export const transition = () => {
  return {
    duration: 0.75,
    delay: 0.2,
    ease: "easeIn",
  };
};
export const transition2 = () => {
  return {
    duration: 0.5,
    delay: 0.05,
    ease: "easeIn",
  };
};
